const ServiceData = [
    
    {
        id: 1,
        imgsrc: "https://yugalkunj.org/images/Bhagti-Ras-Retreat.jpg",
        title: "Bhakti  Ras Retreat",
        Desc: "Click here for more details on  Retreat ",
        Link: "/USA/upcomingevents",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:"__blank"

    },

    

    {
        id: 25,
        imgsrc: "https://yugalkunj.org/images/YugalKunjexpansion.jpg",
        title: "Yugal Kunj expansion  ",
        Desc: "Click here for more details on  Kunj expansion ",
        Link: "#",
        RegistrationLink: "#",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:""

    },

    {
        id: 2,
        imgsrc: "https://yugalkunj.org/images/under-construction-yk.jpg",
        title: "Construction Update  ",
        Desc: "Click here for more details.... ",
        Link: "#",
        RegistrationLink: "#",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:""

    },

    
    // {
    //     id: 1,
    //     imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-6.jpg",
    //     title: " The Deepavali Waiver Agreement ",
    //     Desc: "Please click the Fill button below to complete the waiver agreement. ",
    //     Link: "https://ykportal.yugalkunj.org/Waiveragreement/index",
    //     RegistrationLink: "https://ykportal.yugalkunj.org/Waiveragreement/index",
    //     Readbtn: "",
    //     registerbtn: "Fill",
    //     target:"_blank"

    // },

    
    
    
    // {
    //     id: 4,
    //     imgsrc: "https://yugalkunj.org/images/sharad%20Poornima.jpeg",
    //     title: "Sharad Poornima ",
    //     Desc: "Click here for more details Sharad Poornima ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Read More",
    //     target:""

    // },

    // {
    //     id: 3,
    //     imgsrc: "https://yugalkunj.org/images/ramayan.png",
    //     title: "Ramayan & Exclusive Devotion",
    //     Desc: "Click here for more details Ramayan & Exclusive Devotion",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Read More",
    //     target:""

    // },

    // {
    //     id: 5,
    //     imgsrc: "https://yugalkunj.org/images/diwali.jpeg",
    //     title: "Deepavali Celebrations ",
    //     Desc: "Click here for more details Divali Celebrations ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Read More",
    //     target:""

    // },

    // {
    //     id: 6,
    //     imgsrc: "https://yugalkunj.org/images/goverdhanpooja.jpeg",
    //     title: "Govardhan Pooja",
    //     Desc: "Click here for more details Govardhan Pooja ",
    //     Link: "/USA/upcomingevents",
    //     RegistrationLink: "/USA/upcomingevents",
    //     Readbtn: "Read More",
    //     registerbtn: "Read More",
    //     target:""

    // },


    

    {
        id: 10,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/cause-image-3.jpg",
        title: "Order Prasad",
        Desc: "Prasad is sacred vegetarian food shared with devotees after it has been lovingly prepared and offered to God...",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Order",
        target:""

    },

    {
        id: 26,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-6.jpg",
        title: "Event Rentals ",
        Desc: "Click here for more details on Event Rentals ",
        Link: "/USA/eventrentals",
        RegistrationLink: "https://ykrentals.yugalkunj.org/",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:"_"

    },






    {
        id: 12,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/Geeta-Beginner.png",
        title: "Classes",
        Desc: "Radha Madhav Society offers Gurukul classes to open young minds to spirituality and the richness of Hinduism in a very ...",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },

    
    {
        id: 11,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/featured-image-6.jpg",
        title: "Yugal Kunj Visit",
        Desc: "Click here to register for Yugal Kunj visit registration.",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },




    {
        id: 13,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/inspirational-1.jpg",
        title: "Words of Wisdom",
        Desc: "Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday.",
        Link: "/USA/wordsofwisdom",
        RegistrationLink: "/CAD/wordsofwisdom",
        Readbtn: "Read More",
        registerbtn: "Read Words of Wisdom",
        target:""

    },


    {
        id: 14,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/ykseva.png",
        title: "Cooking  Seva",
        Desc: "Click here to register for cooking  Seva.",
        Link: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        RegistrationLink: "https://ykportal.yugalkunj.org/?ParamCountry=USA",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },


    {
        id: 15,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/about-2.jpg",
        title: "Flowery Grove Montessori School",
        Desc:"We offer genuine Montessori education in a safe, natural setting with serene campus and beautifully prepared classrooms",
        // Desc: "We provide authentic Montessori education in a natural and safe environment. The serene campus, beautifully prepared classroom",
        Link: "https://flowerygrovemontessori.org/",
        RegistrationLink: "https://flowerygrovemontessori.org/",
        Readbtn: "Read More",
        registerbtn: "Register",
        target:""

    },


   






];

export default ServiceData; 