import React, { useState } from 'react';
import MenuBar from './MenuBar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Helmet } from 'react-helmet';

// Reusable Modal Component
const EventModal = ({ show, handleClose, title, imageUrl }) => (
  <Modal show={show} onHide={handleClose} style={{ zIndex: '999999' }}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <figure className="image">
        <a href={imageUrl} download>
          <img src={imageUrl} alt={title} className="img-fluid" />
        </a>
      </figure>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>Close</Button>
    </Modal.Footer>
  </Modal>
);

const AllEvent = () => {
  const [modalState, setModalState] = useState({ show: false, title: '', imageUrl: '' });

  const handleShowModal = (title, imageUrl) => {
    setModalState({ show: true, title, imageUrl });
  };

  const handleCloseModal = () => {
    setModalState({ show: false, title: '', imageUrl: '' });
  };

  const events = [
   
    {
      title: 'Bhakti Ras Retreat',
      imageUrl: 'https://yugalkunj.org/images/Bhagti-Ras-Retreat.jpg',
    },
   
   
    {
      title: 'Yugal Kunj Classes',
      imageUrl: 'https://yugalkunj.org/images/Ca_yugalkunjclasses.jpg',
    },

    {
      title: 'Weekly Satsangs',
      imageUrl: 'https://yugalkunj.org/images/satsang.jpg',
    }


    
  ];

  return (
    <>
      <Helmet>
        <title>Yugalkunj Canada Hamilton</title>
        <meta
          name="description"
          content="Join our exciting Summer Camp in Hamilton 2024! Fun-filled activities, learning opportunities, and memorable experiences for kids of all ages."
        />
        <meta
          name="keywords"
          content="Summer camp Hamilton 2024, Summer camp 2024, summer camp, Hamilton, 2024, kids activities, outdoor fun, learning, education"
        />
        <meta name="author" content="Yugalkunj Canada Hamilton" />
        <link rel="canonical" href="https://yugalkunj.org/Canada/Camps/Summer_Camp_Hamilton" />
      </Helmet>

      <MenuBar />

      {/* Page Banner Section */}
      <section className="page-banner">
        <div className="image-layer"></div>
        <div className="auto-container">
          <h1>Upcoming Events</h1>
        </div>
        <div className="breadcrumb-box">
          <div className="auto-container">
            <ul className="bread-crumb clearfix">
              <li><Link to='/CAD/Home'>Home</Link></li>
              <li className="active">Upcoming Events</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Events Section */}
      <section className="causes-section causes-page" style={{ paddingTop: '40px' }}>
        <div className="auto-container">
          <div className="row clearfix">
            {events.map((event, index) => (
              <div key={index} className="cause-block col-lg-4 col-md-6 col-sm-12">
                <div className="inner-box">
                  <div className="image-box">
                    <figure className="image">
                      <img src={event.imageUrl} className="img-height" alt={event.title} />
                    </figure>
                  </div>
                  <div className="link-box">
                    <button
                      onClick={() => handleShowModal(event.title, event.imageUrl)}
                      className="theme-btn btn-style-two"
                    >
                      <span className="btn-title">View Flyer</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />

      <EventModal
        show={modalState.show}
        handleClose={handleCloseModal}
        title={modalState.title}
        imageUrl={modalState.imageUrl}
      />
    </>
  );
};

export default AllEvent;
